import { FaGlobe, FaInstagramSquare, FaMailBulk, FaMobile } from 'react-icons/fa';

export const logo = '/assets/logo.png';
export const logo_dark = './assets/logo_dark.png';

// About us page
export const description = {
    description_1: 'home_description_part1',
    description_2: 'home_description_part2',
    description_3: 'home_description_part3'
};

export const aboutus = [
    {
        name: 'Andreia Durão',
        job: 'home_person_details_andreia_job',
        photo: '/assets/personal/andreia.jpg',
        description: 'home_person_details_andreia_description',
        description2: "home_person_details_andreia_description2",
        experience: [
            {
                range: '2011',
                job: '',
                description: "home_person_details_andreia_experience_2011"
            },
            {
                range: '2016',
                job: '',
                description: "home_person_details_andreia_experience_2016"
            },
            {
                range: '2022',
                job: '',
                description: "home_person_details_andreia_experience_2022"
            }
        ]
    },
    {
        name: 'Joana Fernandes',
        job: 'home_person_details_joanaf_job',
        photo: '/assets/personal/joanafernandes.jpg',
        description: 'home_person_details_joanaf_description',
        description2: "home_person_details_joanaf_description2",
        experience: [
            {
                range: '2012',
                job: '',
                description: "home_person_details_joanaf_experience_2012"

            },
            {
                range: '2013',
                job: '',
                description: "home_person_details_joanaf_experience_2013"
            },
            {
                range: '2017',
                job: '',
                description: "home_person_details_joanaf_experience_2017"
            }
        ]
    },
    {
        name: 'Joana Ventura',
        job: 'home_person_details_joanav_job',
        photo: '/assets/personal/joanaventura.jpg',
        description: 'home_person_details_joanav_description',
        description2: "home_person_details_joanav_description2",
        experience: [
            {
                range: '2013',
                job: '',
                description: "home_person_details_joanav_experience_2013"

            },
            {
                range: '2013',
                job: '',
                description: "home_person_details_joanav_experience_2014"
            },
            {
                range: '2017',
                job: '',
                description: "home_person_details_joanav_experience_2017"
            }
        ]
    },
    {
        name: 'Paula Pereira',
        job: 'home_person_details_paula_job',
        photo: '/assets/personal/paula.jpg',
        description: 'home_person_details_paula_description',
        description2: "home_person_details_paula_description2",
        experience: [
            {
                range: '1994',
                job: '',
                description: "home_person_details_paula_experience_1994"

            },
            {
                range: '2003',
                job: '',
                description: "home_person_details_paula_experience_2003"
            },
            {
                range: '2007',
                job: '',
                description: "home_person_details_paula_experience_2007"
            }
        ]
    }
];

export const testemonials = [
    {
        description: 'home_testemonials_vanessa',
        author: 'Vanessa'
    },
    {
        description: 'home_testemonials_susana',
        author: 'Susana'
    },
    {
        description: 'home_testemonials_anonimo1',
        author: 'Anónimo'
    },
    {
        description: 'home_testemonials_maria',
        author: 'Maria'
    },
    {
        description: 'home_testemonials_joana',
        author: 'Joana'
    },
    {
        description: "home_testemonials_raquel",
        author: "Raquel"
    },
    {
        description: 'home_testemonials_andreia',
        author: 'Andreia'
    },
    {
        description: 'home_testemonials_patricia',
        author: 'Patricia'
    },
    {
        description: 'home_testemonials_susana2',
        author: 'Susana'
    },
    {
        description: 'home_testemonials_claudia',
        author: 'Cláudia'
    },
    {
        description: 'home_testemonials_rita',
        author: 'Rita'
    },
    {
        description: 'home_testemonials_joana_rita',
        author: 'Joana Rita'
    },
    {
        description: 'home_testemonials_anonimo2',
        author: 'Anónimo'
    },
    {
        description: 'home_testemonials_anonimo3',
        author: 'Anónimo'
    },
    {
        description: 'home_testemonials_anonimo4',
        author: 'Anónimo'
    },
    {
        description: 'home_testemonials_anonimo5',
        author: 'Anónimo'
    },
    {
        description: 'home_testemonials_anonimo6',
        author: 'Anónimo'
    },
    {
        description: 'home_testemonials_anonimo7',
        author: 'Anónimo'
    },
    {
        description: 'home_testemonials_anonimo8',
        author: 'Anónimo'
    },
    {
        description: 'home_testemonials_anonimo9',
        author: 'Anónimo'
    },
    {
        description: 'home_testemonials_anonimo10',
        author: 'Anónimo'
    },
    {
        description: 'home_testemonials_anonimo11',
        author: 'Anónimo'
    },
    {
        description: 'home_testemonials_anonimo12',
        author: 'Anónimo'
    },
    {
        description: 'home_testemonials_anonimo13',
        author: 'Anónimo'
    }
];

export const partners = [
    {
        name: 'home_partners_acd',
        image: "/assets/partners/lifeclub.png",
        link: 'https://academialifeclub.pt/'
    },
    {
        name: "saude_in_city",
        image: "assets/partners/saudeincity.jpg",
        link: "https://www.saudeinthecity.com/?utm_source=google&utm_medium=wix_google_business_profile&utm_campaign=13564162144112941557"
    },
    {
        name: "piscina_municipal_oriente",
        image: "assets/partners/piscina.png",
        link: "https://www.jf-parquedasnacoes.pt/autarquia/10-desporto/27"
    }
];

export const contactus = [
    {
        name: 'mobile',
        icon: <FaMobile />,
        value: '+351 915 402 612'
    },
    {
        name: 'mail',
        icon: <FaMailBulk />,
        value: 'birth.tree.2022@gmail.com'
    },
    {
        name: 'instagram',
        icon: <FaInstagramSquare />,
        value: '@birth_tree'
    },
    {
        name: "site",
        icon: <FaGlobe />,
        value: "https://www.saudeinthecity.com/prenatal-postpartumcare"
    }
];

// Courses page
export const courses_data = [
    {
        name: 'course_detail_prep_nascimento_name',
        short_description: 'course_detail_prep_nascimento_short_description',
        description: "course_detail_prep_nascimento_description",
        price: 'list_item_price_label',
        languages: [
            {
                name: "list_language_portuguese",
                flag: "https://img.freepik.com/free-vector/illustration-portugal-flag_53876-18170.jpg"
            },
            {
                name: "list_language_english",
                flag: "https://static.vecteezy.com/system/resources/thumbnails/007/045/920/small/flag-of-union-jack-uk-england-united-kingdom-flag-illustration-flag-of-great-britain-3d-illustration-3d-illustration-waving-colorful-flag-of-great-britain-free-vector.jpg"
            }
        ],
        structure_description: "course_detail_prep_nascimento_description",
        structure_goals: ["course_detail_prep_nascimento_goals_part1", "course_detail_prep_nascimento_goals_part2", "course_detail_prep_nascimento_goals_part3", "course_detail_prep_nascimento_goals_part4", "course_detail_prep_nascimento_goals_part5"],
        structure: [
            {
                name: "course_detail_prep_session1_name",
                description: ['course_detail_prep_session1_description_part1', "course_detail_prep_session1_description_part2", "course_detail_prep_session1_description_part3", "course_detail_prep_session1_description_part4", "course_detail_prep_session1_description_part5", "course_detail_prep_session1_description_part6", "course_detail_prep_session1_description_part7"]
            },
            {
                name: "course_detail_prep_session2_name",
                description: ['course_detail_prep_session2_description_part1', "course_detail_prep_session2_description_part2"]
            },
            {
                name: "course_detail_prep_session3_name",
                description: ['course_detail_prep_session3_description_part1']
            },
            {
                name: "course_detail_prep_session4_name",
                description: ['course_detail_prep_session4_description_part1', "course_detail_prep_session4_description_part2"]
            }
        ],
        image: '/assets/courses/prep_nascimento.jpg',
        public: "course_detail_prep_public",
        organization: ["course_detail_prep_organization_part1", "course_detail_prep_organization_part2", "course_detail_prep_organization_part3"],
        sessions_options: ["course_detail_prep_sessions_options_part1", "course_detail_prep_sessions_options_part2"]
    }
];

// Services page
export const services_data = [
    {
        name: "services_detail_preparto_name",
        short_description: "services_detail_preparto_short_description",
        description: "services_detail_preparto_description",
        price: "list_item_price_label",
        languages: [
            {
                name: "list_language_portuguese",
                flag: "https://img.freepik.com/free-vector/illustration-portugal-flag_53876-18170.jpg"
            },
            {
                name: "list_language_english",
                flag: "https://static.vecteezy.com/system/resources/thumbnails/007/045/920/small/flag-of-union-jack-uk-england-united-kingdom-flag-illustration-flag-of-great-britain-3d-illustration-3d-illustration-waving-colorful-flag-of-great-britain-free-vector.jpg"
            }
        ],
        structure: [],
        structure_description: "",
        image: '/assets/services/pre-parto.jpg'
    },
    {
        name: "services_detail_posparto_name",
        short_description: "services_detail_posparto_short_description",
        description: "services_detail_posparto_description",
        price: "list_item_price_label",
        languages: [
            {
                name: "list_language_portuguese",
                flag: "https://img.freepik.com/free-vector/illustration-portugal-flag_53876-18170.jpg"
            },
            {
                name: "list_language_english",
                flag: "https://static.vecteezy.com/system/resources/thumbnails/007/045/920/small/flag-of-union-jack-uk-england-united-kingdom-flag-illustration-flag-of-great-britain-3d-illustration-3d-illustration-waving-colorful-flag-of-great-britain-free-vector.jpg"
            }
        ],
        structure_description: "service_details_posparto_structure_description",
        structure: [
            {
                name: "service_details_posparto_structure1_name",
                description: [
                    'service_details_posparto_structure1_topic1',
                    "service_details_posparto_structure1_topic2",
                    "service_details_posparto_structure1_topic3",
                    "service_details_posparto_structure1_topic4"
                ]
            },
            {
                name: "service_details_posparto_structure2_name",
                description: [
                    'service_details_posparto_structure2_topic1',
                    "service_details_posparto_structure2_topic2",
                    "service_details_posparto_structure2_topic3"
                ]
            },
            {
                name: "service_details_posparto_structure3_name",
                description: [
                    'service_details_posparto_structure3_topic1',
                    "service_details_posparto_structure3_topic2"
                ]
            },
            {
                name: "service_details_posparto_structure4_name",
                description: [
                    'service_details_posparto_structure4_topic1',
                    "service_details_posparto_structure4_topic2",
                    "service_details_posparto_structure4_topic3",
                    "service_details_posparto_structure4_topic4",
                    "service_details_posparto_structure4_topic5"
                ]
            },
            {
                name: "service_details_posparto_structure5_name",
                description: [
                    'service_details_posparto_structure5_topic1',
                    "service_details_posparto_structure5_topic2"
                ]
            }
        ],
        image: '/assets/services/pos_parto.jpg'
    },
    {
        name: "services_detail_meio_aquatico_name",
        short_description: "services_detail_meio_aquatico_short_description",
        description: "services_detail_meio_aquatico_description",
        price: "list_item_price_label",
        languages: [
            {
                name: "list_language_portuguese",
                flag: "https://img.freepik.com/free-vector/illustration-portugal-flag_53876-18170.jpg"
            },
            {
                name: "list_language_english",
                flag: "https://static.vecteezy.com/system/resources/thumbnails/007/045/920/small/flag-of-union-jack-uk-england-united-kingdom-flag-illustration-flag-of-great-britain-3d-illustration-3d-illustration-waving-colorful-flag-of-great-britain-free-vector.jpg"
            }
        ],
        structure_description: "",
        structure: [
            {
                name: "services_detail_meio_aquatico_objectivo_title",
                description: ['services_detail_meio_aquatico_objectivo_description']
            },
            {
                name: "services_detail_meio_aquatico_destino_title",
                description: ["services_detail_meio_aquatico_destino_description"]
            },
            {
                name: "services_detail_meio_aquatico_estrutura_title",
                description: ["services_detail_meio_aquatico_estrutura_description"]
            },
            {
                name: "services_detail_meio_aquatico_localizacao_title",
                description: ["services_detail_meio_aquatico_localizacao_description"]
            },
            {
                name: "services_detail_meio_aquatico_instructor_title",
                description: ["services_detail_meio_aquatico_instructor_description"]
            }
        ],
        image: '/assets/services/meio_aquatico.jpg'
    },
]

//workshops
export const workshops_data = [
    {
        name: "workshop_bloco_partos_name",
        short_description: "",
        description: "",
        price: "list_item_price_label",
        languages: [
            {
                name: "list_language_portuguese",
                flag: "https://img.freepik.com/free-vector/illustration-portugal-flag_53876-18170.jpg"
            },
            {
                name: "list_language_english",
                flag: "https://static.vecteezy.com/system/resources/thumbnails/007/045/920/small/flag-of-union-jack-uk-england-united-kingdom-flag-illustration-flag-of-great-britain-3d-illustration-3d-illustration-waving-colorful-flag-of-great-britain-free-vector.jpg"
            }
        ],
        structure: [
            {
                name: "workshop_bloco_partos_session1_name",
                description: ['workshop_bloco_partos_session1_description_part1', "workshop_bloco_partos_session1_description_part2"]
            },
            {
                name: "workshop_bloco_partos_session2_name",
                description: ['workshop_bloco_partos_session2_description_part1']
            },
            {
                name: "workshop_bloco_partos_session3_name",
                description: ['workshop_bloco_partos_session3_description_part1', 'workshop_bloco_partos_session3_description_part2', 'workshop_bloco_partos_session3_description_part3']
            },
            {
                name: "workshop_bloco_partos_session4_name",
                description: ['workshop_bloco_partos_session4_description_part1', 'workshop_bloco_partos_session4_description_part2']
            }
        ],
        structure_description: "",
        image: '/assets/workshops/bloco_partos.jpg'
    },
    {
        name: "workshop_plano_nascimento_name",
        short_description: "",
        description: "",
        price: "list_item_price_label",
        languages: [
            {
                name: "list_language_portuguese",
                flag: "https://img.freepik.com/free-vector/illustration-portugal-flag_53876-18170.jpg"
            },
            {
                name: "list_language_english",
                flag: "https://static.vecteezy.com/system/resources/thumbnails/007/045/920/small/flag-of-union-jack-uk-england-united-kingdom-flag-illustration-flag-of-great-britain-3d-illustration-3d-illustration-waving-colorful-flag-of-great-britain-free-vector.jpg"
            }
        ],
        structure: [
            {
                name: "workshop_plano_nascimento_session1_name",
                description: []
            },
            {
                name: "workshop_plano_nascimento_session2_name",
                description: []
            },
            {
                name: "workshop_plano_nascimento_session3_name",
                description: []
            },
            {
                name: "workshop_plano_nascimento_session4_name",
                description: ['workshop_plano_nascimento_session4_description_part1', 'workshop_plano_nascimento_session4_description_part2']
            },
            {
                name: "workshop_plano_nascimento_session5_name",
                description: []
            }
        ],
        structure_description: "",
        image: '/assets/workshops/plano_nascimento.jpg'
    },
    {
        name: "workshop_pos_parto_name",
        short_description: "",
        description: "",
        price: "list_item_price_label",
        languages: [
            {
                name: "list_language_portuguese",
                flag: "https://img.freepik.com/free-vector/illustration-portugal-flag_53876-18170.jpg"
            },
            {
                name: "list_language_english",
                flag: "https://static.vecteezy.com/system/resources/thumbnails/007/045/920/small/flag-of-union-jack-uk-england-united-kingdom-flag-illustration-flag-of-great-britain-3d-illustration-3d-illustration-waving-colorful-flag-of-great-britain-free-vector.jpg"
            }
        ],
        structure: [
            {
                name: "workshop_pos_parto_session1_name",
                description: []
            },
            {
                name: "workshop_pos_parto_session2_name",
                description: ['workshop_pos_parto_session2_description_part1', 'workshop_pos_parto_session2_description_part2']
            },
            {
                name: "workshop_pos_parto_session3_name",
                description: []
            },
            {
                name: "workshop_pos_parto_session4_name",
                description: []
            },
            {
                name: "workshop_pos_parto_session5_name",
                description: []
            }
        ],
        structure_description: "",
        image: '/assets/workshops/parto_vaginal.jpg'
    },
    {
        name: "workshop_parto_name",
        short_description: "",
        description: "",
        price: "list_item_price_label",
        languages: [
            {
                name: "list_language_portuguese",
                flag: "https://img.freepik.com/free-vector/illustration-portugal-flag_53876-18170.jpg"
            },
            {
                name: "list_language_english",
                flag: "https://static.vecteezy.com/system/resources/thumbnails/007/045/920/small/flag-of-union-jack-uk-england-united-kingdom-flag-illustration-flag-of-great-britain-3d-illustration-3d-illustration-waving-colorful-flag-of-great-britain-free-vector.jpg"
            }
        ],
        structure: [
            {
                name: "workshop_parto_session1_name",
                description: ['workshop_parto_session1_description_part1']
            },
            {
                name: "workshop_parto_session2_name",
                description: ['workshop_parto_session2_description_part1']
            },
            {
                name: "workshop_parto_session3_name",
                description: ['workshop_parto_session3_description_part1', 'workshop_parto_session3_description_part2', 'workshop_parto_session3_description_part3']
            }
        ],
        structure_description: "",
        image: '/assets/workshops/regresso_casa.jpg'
    }
]

// Posts
export const posts_data = [
    {
        url: "https://www.instagram.com/p/Cqqli-evrAX/?utm_source=ig_web_copy_link"
    },
    {
        url: "https://www.instagram.com/p/CqdtkXpI62F/?utm_source=ig_web_copy_link"
    },
    {
        url: "https://www.instagram.com/p/CqGl14Qo2dh/?utm_source=ig_web_copy_link"
    },
    {
        url: "https://www.instagram.com/p/Co-v7hhPcMj/?utm_source=ig_web_copy_link"
    }
];